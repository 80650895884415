/**
* Template Name: Sailor - v2.3.1
* Template URL: https://bootstrapmade.com/sailor-free-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* .carousel-inner img {
  width: 100%;
  height: 100%;
} */


.imageHolder {
  position: relative;
  /* width: 285px;
  height: 175px; */
}
.imageHolder .caption {
  position: absolute;
  /* width: 283px; */
  width:100%;
  height: 25px;
  bottom: 0px;
  
  left: 0px;
  color: #ffffff;
  background: black;
  text-align:center;
  font-weight:bold;
  opacity:0.7;
}


/*Merquee scrolling*/
.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.containermerquee {
  /* width: 18em; */
  height: 15em;
  margin: 1em auto;
  overflow: hidden;
  /* background: white; */
  position: relative;
  box-sizing: border-box;
}

.marquee {
  top: 6em;
  position: relative;
  box-sizing: border-box;
  animation: marquee 15s linear infinite;
}

.marquee:hover {
  animation-play-state: paused;
}

/* Make it move! */
@keyframes marquee {
  0%   { top:   8em }
  100% { top: -11em }
}

/* Make it look pretty */
.microsoft .marquee {
margin: 0;
  padding: 0 1em;
  line-height: 1.5em;
  font: 1em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
}

.microsoft:before, .microsoft::before,
.microsoft:after,  .microsoft::after {
  left: 0;
  z-index: 1;
  content: '';
  position: absolute;
  pointer-events: none;
  width: 100%; height: 2em;
  background-image: linear-gradient(top, #FFF, rgba(255,255,255,0));
}

.microsoft:after, .microsoft::after {
  bottom: 0;
  transform: rotate(180deg);
}

.microsoft:before, .microsoft::before {
  top: 0;
}

/* Style the links */
.vanity {
  color: #333;
  text-align: center;
  font: .75em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
}

.vanity a, .microsoft a {
  color: #1570A6;
  transition: color .5s;
  text-decoration: none;
}

.vanity a:hover, .microsoft a:hover {
  color: #F65314;
}

/* Style toggle button */
.toggle {
display: block;
  margin: 2em auto;
}

/*End merquee scrolling*/


.masthead {
  height: 100vh;
  min-height: 500px;
  /* background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080'); */
  background-image: url('../images/cover.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.textStyling{
  text-align: justify;
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .textStyling {
   color: #000;
  font-size: 20px;
  font-weight: 700;
  text-align:start;  
    
  }
}

.homepage-testimonials {
   
  font-size: 15px;
  font-weight: 700;
  color: #000;
  
}

@media only screen and (max-width: 600px) {
  .homepage-testimonials {
   
    font-size: 15px;
    font-weight: 900;
    color: #000;
    
  }
}
.carousel-bottomsection-margin {
   
  margin-top: -100px;
  margin-bottom: 50px;
  
}

@media only screen and (max-width: 600px) {
  .carousel-bottomsection-margin {
   
    margin-top: 50px;
    
  }
}

.fillRemaining {
  margin-top: -120px;
  }

  .booksmargin {
    margin-left: 10px;
    }
    @media only screen and (max-width: 600px) {
      .booksmargin {
        margin-left: 3px;
        }
    }
  @media only screen and (max-width: 600px) {
    .fillRemaining {
      margin-top: 0px;
      }
  }
  .fillRemainingExtraSmall {
    margin-top: -190px;
    }
    @media only screen and (max-width: 600px) {
      .fillRemainingExtraSmall {
        margin-top: 0px;
        }
    }
    .fillRemainingExtraMore {
      margin-top: -390px;
      }
      @media only screen and (max-width: 600px) {
        .fillRemainingExtraMore {
          margin-top: 0px;
          }
      }
.img-hover-zoom {
  height: 300px; 
  overflow: hidden; 
}


.img-hover-zoom img {
  transition: transform .5s ease;
}


.img-hover-zoom:hover img {
  transform: scale(1.5);
}

@media only screen and (max-width: 600px) {
  .img-hover-zoom {
    height: 0px; 
    overflow: hidden; 
  }
  
  
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  
  .img-hover-zoom:hover img {
    transform: scale(1);
  }
}


.photo1 {
 
  width: 50%;
  float: right;
  margin-top: 210px;
 
}
.photo2 {
 
  width: 50%;
  float: right;
  margin-top: 210px;
 
}
.photo3 {
 
  width: 50%;
  float: right;
  /* margin-top: 210px; */
 
}
.red-color {
 
  color:red
}

@media only screen and (max-width: 600px) {
  .photo1 {
    width: 70%;
  float: right;
  margin-top: 100px;
  }
  .photo2 {
    width: 70%;
  float: right;
  margin-top: 100px;
  }
  .photo3 {
    width: 70%;
  float: right;
  margin-top: 10px;
  }
}

.about_banner_text_caption  {
  float: left;
  font-size: 40px;
 
}
.banner_second_line{
  float: left;
  font-size: 30px;
}
.banner_third_line{
  float: left;
  font-size: 30px;
 
}
@media only screen and (max-width: 600px) {
  .about_banner_text_caption  {
    float: left;
    font-size: 20px;
   
  }
  .banner_second_line{
    float: left;
    font-size: 12px;
    margin-right: 20px;
  }
  .banner_third_line{
    float: left;
    font-size: 9px;
    margin-right: 20px;
   
  }
}

.carousel,
.carousel-inner {
  height: 100vh;   
}

@media only screen and (max-width: 600px) {
.carousel,
.carousel-inner {
  height: 70vh; /*40 vh was*/
  margin-top: 10px;   
}
}

/* @media only screen and (max-width: 480px) {
  .carousel,
  .carousel-inner {
    height: 40vh;
    margin-top: 0px;   
  }
  } */



.about-carousel{
  height:150vh;
  background-color: #000;
}

/*Testimonials*/
.carousel-root {
  width: 90% !important;
  margin: auto !important;
  margin-top: 3% !important;
  
}

.carousel .slide {
  /* background: #1e2124 !important;
  color: black; */
  background: #ffffff !important;
  color: black;
  height: 100%;
}

.carousel .slide img {
  width: 139px !important;
  border-radius: 50%;
}

.myCarousel {
  background: #ffffff;
  margin-top: -6%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  /* padding-bottom: 8%; */
  padding-left: 5%;
  padding-right: 5%;
  /* border: 1px solid #ddd; */
  height: 400px;    
}

/* .carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
} */

.myCarousel h3 {
  color:#000;  
  font-weight: 600;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  margin-top: 20px;
  font-weight: 600;
  /* text-transform: uppercase; */
  font-size: 20px;
}

.myCarousel h4 {
  
  /* text-transform: uppercase; */
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

/* .myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #000;
  font-size: 18px;
  font-family: sans-serif;
  max-height: 67px;
  text-align: justify;
  margin-top: 30px;
} */
.myCarousel p {
  font-weight: 700 !important;
  line-height: 29px !important;
  color: #000;
  font-size: 18px;
  /* font-family: sans-serif; */
  max-height: 67px;
  text-align: justify;
  margin-top: 30px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #ffffff !important;
  outline: 0;
  margin-top: 0px;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    /* background: #1e2124; */
    background: #ffffff;
    margin-top: -9%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    /* border: 1px solid #ddd; */
    height: 400px;
  }

  .carousel .slide img {
    width: 24% !important;
    border-radius: 50%;
  }
}

/*Testimonials end*/




body {
  font-family: Nunito,sans-serif;
  /* color: #444444;
  background-color: #1e2124; */
  color: #000;
  background-color: #f9f9f9;
  height: 100%;
}

a {
  color: rgb(28, 92, 147);
}

a:hover {
  color: #0056b3;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Nunito, sans-serif;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #b033fa;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #e1444d;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  background: #343a40!important;
  transition: all 0.5s;
  z-index: 997;
  padding: 10px 0;
}

#header.header-scrolled {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}

#header.header-inner-pages {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 22px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  /* text-transform: uppercase; */
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}

@media (max-width: 992px) {
  #header {
    padding: 12px 0;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 12px 0 12px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #fff;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 500;
  font-family: Nunito,sans-serif;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  /* color: #b033fa; */

  color: #FFFF00;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 15px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
  background-color: #343a40!important;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 500;
  text-transform: none;
  color: #fff;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  /* color: #b033fa; */
  color: #FFFF00;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Get Startet Button */
.get-started-btn {
  margin-left: 25px;
  background: #6718a8;
  color: #fff;
  border-radius: 5px;
  padding: 8px 15px 9px 15px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 15px;
  display: inline-block;
  font-family: Nunito,sans-serif;
}

.get-started-btn:hover {
  background: #b033fa;
  color: #fff;
}

@media (max-width: 991px) {
  .get-started-btn {
    margin: 0 48px 0 0;
    padding: 6px 18px;
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #556270;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #343a40!important;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #FFFF00;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(63, 73, 83, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  
}

@media only screen and (max-width: 600px) {
#hero {
  width: 100%;
  height: 40vh;
  
  /* background-color: rgba(63, 73, 83, 0.8); */
  overflow: hidden;
  position: relative;
}
}

#hero .carousel, #hero .carousel-inner, #hero .carousel-item, #hero .carousel-item::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-item {
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height:100% */
  border: 1px solid grey;
  max-height: 100vh;
  min-height: 50vh;
  max-width: 100vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: '';
  background-color: rgba(30, 35, 40, 0.349);
}

/* #hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
} */
.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 50px;
  right: 50px;
  
}
@media only screen and (max-width: 600px) {
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 70px;
    left: 50px;
    right: 50px;
    bottom: 100px;
  }
}
#hero .container {
  text-align: center;
}

#hero .carousel-image-container {
  display: flex;
  justify-content: right;
  align-items: right;
  position: absolute;
  bottom: 0;
  top: 70px;
  left: 80px;
  right: 50px;
}

#hero .image-container {
  text-align: right;
  /* float: right; */
}

#hero h2 {
  color: #fff;
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 700;
}

@media only screen and (max-width: 600px) {
#hero h2 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 700;
}
}

#hero p {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev, #hero .carousel-control-next {
  width: 10%;
  top: 112px;
}

@media (max-width: 992px) {
  #hero .carousel-control-prev, #hero .carousel-control-next {
    top: 66px;
  }
}

/* #hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
  background: none;
  font-size: 36px;
  line-height: 1;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  padding: 10px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.5);
}

#hero .carousel-control-next-icon:hover, #hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.8);
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #fff;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  opacity: .6;
  transition: 0.3s;
} */

#hero .carousel-indicators li.active {
  opacity: 1;
  /* background: #FFFFFF; */
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 32px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background:#ff0000;
}

#hero .btn-get-started:hover {
  
   background: #FFFF00; 
 
}

@media (max-width: 992px) {
  /* #hero {
    height: 100vh;
  } */
  #hero .carousel-container {
    top: 8px;
  }
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }
}

@media (min-width: 1024px) {
  #hero .carousel-control-prev, #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg, .services .icon-box {
  background-color: #f8f9fa;
  /* background-color:  #C0C0C0; */
}

.section-title {
  padding-bottom: 40px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  /* text-transform: uppercase; */
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #e6636a;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #556270;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 18px 0;
  background: #f8f9fa;
  min-height: 40px;
  margin-top: 86px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }
}

.breadcrumbs h2 {
  font-size: 32px;
  font-weight: 300;
  margin: 0;
  color: #000;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6b7b8d;
  content: "/";
}

@media (max-width: 992px) {
  .breadcrumbs {
    /* margin-top: 58px; */
    /* margin-top: 5px; */
  }
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs h2 {
    margin-bottom: 10px;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 20px;
  /* text-transform: uppercase; */
}

.about .content h3 {
  font-weight: 500;
  line-height: 32px;
  font-size: 24px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding: 10px 0 0 28px;
  position: relative;
}

.about .content ul i {
  left: 0;
  top: 7px;
  position: absolute;
  font-size: 20px;
  color: #d9232d;
}

.about .content p:last-child {
  margin-bottom: 0;
  color: #000;
}

/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/
.clients {
  padding: 15px 0;
  text-align: center;
}

.clients img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}

.clients img:hover {
  filter: none;
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .clients img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 6px;
  /* height:390px */
}

.services .icon-box i {
  float: left;
  color: #d9232d;
  font-size: 40px;
}

.services .icon-box h4 {
  margin-left: 0px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 a {
  color: #221638;
  transition: 0.3s;
}

.services .icon-box p {
  /* margin-left: 70px; */
  margin-left: 0px;
  line-height: 24px;
  font-size: 14px;
}

.services .icon-box:hover h4 a {
  color: #b033fa;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover, .portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #d9232d;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(85, 98, 112, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(85, 98, 112, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: rgba(255, 255, 255, 0.6);
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: white;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Our Team
--------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
  padding: 30px;
  border-radius: 4px;
  background: white;
}

.team .member .pic {
  overflow: hidden;
  /* width: 140px; */
  width: 900px;
  border-radius: 4px;
}

.team .member .pic img {
  transition: ease-in-out 0.3s;
}

.team .member:hover img {
  transform: scale(1.1);
}

.team .member .member-info {
  padding-left: 30px;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #556270;
}

.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}

.team .member span::after {
  content: '';
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #dee2e6;
  bottom: 0;
  left: 0;
}

.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}

.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  background: #8795a4;
}

.team .member .social a i {
  color: #fff;
  font-size: 16px;
  margin: 0 2px;
}

.team .member .social a:hover {
  background: #d9232d;
}

.team .member .social a + a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 50px;
  display: block;
  background: none;
}

.skills .progress .skill {
  padding: 10px 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #556270;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: white;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: .9s;
  background-color: #6b7b8d;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features {
  overflow: hidden;
}

.features .nav-tabs {
  border: 0;
}

.features .nav-link1 {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #556270;
  border-radius: 0;
  border-right: 2px solid white;
  font-weight: 600;
  font-size: 15px;
}

.features .nav-link:hover {
  color: #d9232d;
}

.features .nav-link.active {
  color: #d9232d;
  border-color: #d9232d;
}

.features .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.features .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #556270;
}

.features .details p {
  color: #777777;
}

.features .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .features .nav-link {
    border: 0;
    padding: 15px;
  }
  .features .nav-link.active {
    color: #fff;
    background: #d9232d;
  }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .box {
  padding: 20px;
  background: #fff;
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.pricing h3 {
  font-weight: 400;
  margin: -20px -20px 20px -20px;
  padding: 20px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #777777;
  background: #f8f8f8;
}

.pricing h4 {
  font-size: 36px;
  color: #d9232d;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.pricing h4 sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 16px;
  font-weight: 300;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #444444;
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.pricing ul li {
  padding-bottom: 16px;
}

.pricing ul i {
  color: #d9232d;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
  text-decoration: line-through;
}

.pricing .btn-wrap {
  margin: 20px -20px -20px -20px;
  padding: 20px 15px;
  background: #f8f8f8;
  text-align: center;
}

.pricing .btn-buy {
  background: #d9232d;
  display: inline-block;
  padding: 8px 35px 9px 35px;
  border-radius: 4px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  transition: 0.3s;
}

.pricing .btn-buy:hover {
  background: #e1444d;
}

.pricing .featured h3 {
  color: #fff;
  background: #d9232d;
}

.pricing .advanced {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: #d9232d;
  color: #fff;
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-item {
  margin: 20px 0;
  padding: 20px 0;
  border-bottom: 1px solid white;
}

.faq .faq-item i {
  color: #dee2e6;
  font-size: 20px;
  float: left;
  line-height: 0;
  padding: 13px 0 0 0;
  margin: 0;
}

.faq .faq-item h4 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0 0 10px 28px;
  font-family: "Poppins", sans-serif;
}

.faq .faq-item p {
  font-size: 15px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #f8d1d3;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #556270;
  float: left;
  width: 44px;
  height: 44px;
  background: #edeff1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #556270;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #8795a4;
}

.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: #556270;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input, .contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus, .contact .php-email-form textarea:focus {
  border-color: #d9232d;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #d9232d;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e24d55;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details .portfolio-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-details-carousel .owl-nav, .portfolio-details .portfolio-details-carousel .owl-dots {
  margin-top: 5px;
  text-align: center;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
  background-color: #d9232d !important;
}

.portfolio-details .portfolio-info {
  padding-top: 45px;
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-info p {
  font-size: 15px;
  padding: 15px 0 0 0;
}

@media (max-width: 992px) {
  .portfolio-details .portfolio-info {
    padding-top: 20px;
  }
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
  padding: 10px 0 0px 0;
}

.blog .entry {
  padding: 20px;
  margin-bottom: 60px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .entry .entry-img {
  max-height: 400px;
  margin: -20px -20px 20px -20px;
  
  overflow: hidden; 
  position: relative;
  padding-top: 10%;
}
.video-container-Gallery {
  overflow: hidden;
  position: relative;
  width:100%;
  
}

.video-container-Gallery::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.video-container-Gallery iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.section-margin{
  margin-top: 50px;
}
@media only screen and (max-width: 600px) {
  .section-margin{
    margin-top: 0px;
  }
  .video-container-Gallery {
    overflow: hidden;
    position: relative;
    width:100%;
    
  }
  
  .video-container-Gallery::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .video-container-Gallery iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 20px;
  }
}

/*Video container*/
.video-container {
  overflow: hidden;
  position: relative;
  width:60%;
  
}

.video-container::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    
  }
  
  .video-container::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/*Caption Text Alignment*/
.text-alignment{
  margin-top: 90px;
}
@media only screen and (max-width: 600px) {
  .text-alignment {
    margin-top: 10px;
    
  }
}
/*GIF container*/
.bookcover-container {
  overflow: hidden;
  position: relative;
  width:100%;
  
}

.bookcover-container::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.bookcover-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .bookcover-container {
    overflow: hidden;
    position: relative;
    width:100%;
    
  }
  
  .bookcover-container::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .bookcover-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



/*GIF container*/
.gif-container {
  overflow: hidden;
  position: relative;
  width:60%;
  
}

.gif-container::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.gif-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .gif-container {
    overflow: hidden;
    position: relative;
    width:100%;
    
  }
  
  .gif-container::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .gif-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*Gallery Container*/
.gallery-container {
  overflow: hidden;
  position: relative;
  width:100%;
  
}

.gallery-container::after {
  padding-top: 50%;
  display: block;
  content: '';
}

.gallery-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 278px;
  height: 185px;
}

@media only screen and (max-width: 600px) {
  .gallery-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-top: 10px;
    
  }
  
  .gallery-container::after {
    padding-top: 50%;
    display: block;
    content: '';
  }
  
  .gallery-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/*Gallery container end*/

/*Icon Container*/
.icon-container {
  /* overflow: hidden; */
  position: relative;
 
  width:100%;
  /* margin-left: 120px; */
 /* align-content:flex-end; */
  
}

.icon-container::after {
  padding-top: 5%;
  display: block;
  content: '';
}

.icon-container img {
  position: relative;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  /* align-content: center; */
}
.offeredCoursesCardHeight{
  height:500px;
}
@media only screen and (max-width: 600px) {
  .icon-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-top: 10px;
    /* margin-left: 180px */
    
  }
  .offeredCoursesCardHeight{
    height:600px;
  }
  .icon-container::after {
    padding-top: 5%;
    display: block;
    content: '';
  }
  
  .icon-container img {
    position: relative;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
  }
}
/*Icon container end*/


/*SocialIcon container*/
.socialicon-container {
  overflow: hidden;
  position: relative;
  width:100%;
  margin-left: 120px;
  
}

.socialicon-container::after {
  padding-top: 20%;
  display: block;
  content: '';
}

.socialicon-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
}

@media only screen and (max-width: 600px) {
  .socialicon-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-top: 10px;
    margin-left: 180px
    
  }
  
  .socialicon-container::after {
    padding-top: 20%;
    display: block;
    content: '';
  }
  
  .socialicon-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
  }
}
/*SocialLogo container*/


.blog .entry .entry-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  padding: 0;
  margin: 0 0 20px 0;
}

.blog .entry .entry-title a {
  color: #556270;
  transition: 0.3s;
}

.blog .entry .entry-title a:hover {
  color: #d9232d;
}

.blog .entry .entry-meta {
  margin-bottom: 15px;
  color: #c1c8d0;
}

.blog .entry .entry-meta ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog .entry .entry-meta ul li + li {
  padding-left: 20px;
}

.blog .entry .entry-meta i {
  font-size: 14px;
  padding-right: 4px;
}

.blog .entry .entry-meta a {
  color: #a4afba;
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog .entry .entry-content p {
  line-height: 24px;
  font-size: 15px;
}

.blog .entry .entry-content .read-more {
  -moz-text-align-last: right;
  text-align-last: right;
}

.blog .entry .entry-content .read-more a {
  display: inline-block;
  background: #d9232d;
  color: #fff;
  padding: 6px 20px 7px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 4px;
}

.blog .entry .entry-content .read-more a:hover {
  background: #e1444d;
}

.blog .entry .entry-content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog .entry .entry-content blockquote {
  overflow: hidden;
  background-color: #fafafa;
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog .entry .entry-content blockquote p {
  color: #444444;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog .entry .entry-content blockquote .quote-left {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote .quote-right {
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 36px;
  color: #e7e7e7;
}

.blog .entry .entry-content blockquote::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #556270;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog .entry .entry-footer {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
}

.blog .entry .entry-footer i {
  color: #8795a4;
  display: inline;
}

.blog .entry .entry-footer a {
  color: #a4afba;
  transition: 0.3s;
}

.blog .entry .entry-footer a:hover {
  color: #d9232d;
}

.blog .entry .entry-footer .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog .entry .entry-footer .cats li {
  display: inline-block;
}

.blog .entry .entry-footer .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog .entry .entry-footer .tags li {
  display: inline-block;
}

.blog .entry .entry-footer .tags li + li::before {
  padding-right: 6px;
  color: #6c757d;
  content: ",";
}

.blog .entry .entry-footer .share {
  font-size: 16px;
}

.blog .entry .entry-footer .share i {
  padding-left: 5px;
}

.blog .entry-single {
  margin-bottom: 30px;
}

.blog .entry-single .entry-title {
  font-size: 32px;
  line-height: 38px;
}

.blog .blog-author {
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-author img {
  width: 120px;
}

.blog .blog-author h4 {
  margin-left: 140px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding: 0;
}

.blog .blog-author .social-links {
  margin: 0 0 5px 140px;
}

.blog .blog-author .social-links a {
  color: #c1c8d0;
}

.blog .blog-author p {
  margin-left: 140px;
  font-style: italic;
  color: #b7b7b7;
}

.blog .blog-comments {
  margin-bottom: 30px;
}

.blog .blog-comments .comments-count {
  font-weight: bold;
}

.blog .blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog .blog-comments .comment .comment-img {
  width: 50px;
}

.blog .blog-comments .comment h5 {
  margin-left: 65px;
  font-size: 16px;
  margin-bottom: 2px;
}

.blog .blog-comments .comment h5 a {
  font-weight: bold;
  color: #444444;
  transition: 0.3s;
}

.blog .blog-comments .comment h5 a:hover {
  color: #d9232d;
}

.blog .blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: #556270;
}

.blog .blog-comments .comment time {
  margin-left: 65px;
  display: block;
  font-size: 14px;
  color: #c1c8d0;
  margin-bottom: 5px;
}

.blog .blog-comments .comment p {
  margin-left: 65px;
}

.blog .blog-comments .comment.comment-reply {
  padding-left: 40px;
}

.blog .blog-comments .reply-form {
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .blog-comments .reply-form h4 {
  font-weight: bold;
  font-size: 22px;
}

.blog .blog-comments .reply-form p {
  font-size: 14px;
}

.blog .blog-comments .reply-form input {
  border-radius: 0;
  padding: 20px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form input:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form textarea {
  border-radius: 0;
  padding: 10px 10px;
  font-size: 14px;
}

.blog .blog-comments .reply-form textarea:focus {
  box-shadow: none;
  border-color: #e9797f;
}

.blog .blog-comments .reply-form .form-group {
  margin-bottom: 25px;
}

.blog .blog-comments .reply-form .btn-primary {
  border-radius: 4;
  padding: 10px 20px;
  border: 0;
  background-color: #556270;
}

.blog .blog-comments .reply-form .btn-primary:hover {
  background-color: #d9232d;
}

.blog .blog-pagination {
  color: #8795a4;
}

.blog .blog-pagination ul {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.blog .blog-pagination li {
  border: 1px solid #edeff1;
  margin: 0 5px;
  transition: 0.3s;
}

.blog .blog-pagination li.active {
  background: white;
}

.blog .blog-pagination li a {
  color: #aaaaaa;
  padding: 7px 16px;
  display: inline-block;
}

.blog .blog-pagination li.active, .blog .blog-pagination li:hover {
  background: #d9232d;
  border: 1px solid #d9232d;
}

.blog .blog-pagination li.active a, .blog .blog-pagination li:hover a {
  color: #fff;
}

.blog .blog-pagination li.disabled {
  background: #fff;
  border: 1px solid white;
}

.blog .blog-pagination li.disabled i {
  color: #f1f1f1;
  padding: 10px 16px;
  display: inline-block;
}

.blog .sidebar {
  padding: 30px;
  margin: 0 0 60px 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog .sidebar .sidebar-title {
  font-size: 20px;
  font-weight: 700;
  padding: 0 0 0 0;
  margin: 0 0 15px 0;
  color: #556270;
  position: relative;
}

.blog .sidebar .sidebar-item {
  margin-bottom: 30px;
}

.blog .sidebar .search-form form {
  background: #fff;
  border: 1px solid #556270;
  padding: 3px 10px;
  position: relative;
  border-radius: 4px;
}

.blog .sidebar .search-form form input[type="text"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 40px);
}

.blog .sidebar .search-form form button {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  background: #556270;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

.blog .sidebar .search-form form button:hover {
  background: #6b7b8d;
}

.blog .sidebar .categories ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .categories ul li + li {
  padding-top: 10px;
}

.blog .sidebar .categories ul a {
  color: #6b7b8d;
}

.blog .sidebar .categories ul a:hover {
  color: #d9232d;
}

.blog .sidebar .categories ul a span {
  padding-left: 5px;
  color: #c1c8d0;
  font-size: 14px;
}

.blog .sidebar .recent-posts .post-item + .post-item {
  margin-top: 15px;
}

.blog .sidebar .recent-posts img {
  width: 80px;
  float: left;
}

.blog .sidebar .recent-posts h4 {
  font-size: 15px;
  margin-left: 95px;
  font-weight: bold;
}

.blog .sidebar .recent-posts h4 a {
  color: #292f36;
  transition: 0.3s;
}

.blog .sidebar .recent-posts h4 a:hover {
  color: #d9232d;
}

.blog .sidebar .recent-posts time {
  display: block;
  margin-left: 95px;
  font-style: italic;
  font-size: 14px;
  color: #c1c8d0;
}

.blog .sidebar .tags {
  margin-bottom: -10px;
}

.blog .sidebar .tags ul {
  list-style: none;
  padding: 0;
}

.blog .sidebar .tags ul li {
  display: inline-block;
}

.blog .sidebar .tags ul a {
  color: #96a2af;
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid #dee2e6;
  display: inline-block;
  border-radius: 4px;
  transition: 0.3s;
}

.blog .sidebar .tags ul a:hover {
  color: #fff;
  border: 1px solid #d9232d;
  background: #d9232d;
}

.blog .sidebar .tags ul a span {
  padding-left: 5px;
  color: #fbfbfc;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.wrapper {
  padding: 50px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#footer {
 
  background: #4a5562;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;

}

#footer .footer-top {
  background: #515d6a;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #b033fa;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: white;
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px 2px 20px;
  background: #d9232d;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #df3740;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
}

#footer .credits a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
  font-weight: 600;
}

#footer .credits a:hover {
  color: white;
}
